//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Modal, { ModalMixin } from "@/components/modals/layouts/Default.vue";
import { required, email, FormMixin, default as FormValidatable } from "@/components/Form/Form";
const _emailCache = {};
const emailAvailable = (vm, inverse, msg) => async v => {
  if (_emailCache[v] === undefined) {
    _emailCache[v] = await vm.$store.dispatch("user/checkEmail", {
      user_email_address: v
    });
  }
  if (!inverse && !_emailCache[v] || inverse && _emailCache[v]) {
    return msg || "Email уже занят";
  }
};
export default {
  mixins: [ModalMixin, FormMixin],
  components: {
    Modal,
    FormValidatable
  },
  props: {},
  data() {
    return {
      active_inputs: [{
        name: "user_email_address",
        title: "Email",
        placeholder: "Email",
        type: "email",
        error_text: "",
        show_error: false,
        show_success: false,
        model: "",
        active: false,
        touched: false,
        validators: [required(), email(), emailAvailable(this)],
        show_name: false
      }],
      loading: false
    };
  },
  mounted() {},
  methods: {
    onOpen() {},
    async submit({
      close
    }) {
      const isValid = await this.validate(true);
      if (!isValid) {
        return;
      }
      this.loading = true;
      try {
        await this.$store.dispatch("user/inviteChild", {
          user_email_address: this.active_inputs.find(i => i.name === "user_email_address").model.trim()
        });
        this.$emit("complete");
        close();
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    }
  },
  computed: {
    valid() {
      return true;
    },
    actions() {
      return [
      // "cancel",
      {
        name: "submit",
        text: "Отправить приглашение",
        action: ({
          close
        }) => {
          this.submit({
            close
          });
        },
        disabled: !this.valid,
        loading: this.loading
      }];
    }
  }
};